import { h, FunctionalComponent } from 'preact';
import { Table, Pane } from 'evergreen-ui';
import { CalendarItem } from "../CommonTypes";
import { format, isToday } from "date-fns";

interface CalendarTableProps {
    calendarData: CalendarItem[];
    startIndex: number;
}

export const CalendarTable: FunctionalComponent<CalendarTableProps> = ({ calendarData, startIndex }) => {

    const handleRowClick = (item: CalendarItem) => {
        const formattedDate = format(new Date(item.start_date), 'MM-dd-yyyy');
        const url = `https://sof.advantagebooking.com/book/clinic/${formattedDate}#dialogs=sessioninfo/calendar/${item.source_id}`;
        window.open(url, '_blank');
    };

    return (
        <div>
            {calendarData.length > 0 ? (
                <Table>
                    <Table.Head>
                        <Table.TextHeaderCell flexBasis={70} flexShrink={0} flexGrow={0}>#</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Title</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Date and Time</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Is Robin</Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body>
                        {calendarData.map((item, index) => {
                            const eventDate = new Date(item.start_date);
                            const isHighlighted = isToday(eventDate);
                            return (

                                <Table.Row
                                    onClick={() => handleRowClick(item)}
                                    key={item.id}
                                    isSelectable
                                    style={{ backgroundColor: isHighlighted ? '#e0ffe0' : '' }}
                                    onMouseEnter={(e: MouseEvent) => (e.currentTarget as HTMLTableRowElement).style.backgroundColor = '#f0f0f0'}
                                    onMouseLeave={(e: MouseEvent) => (e.currentTarget as HTMLTableRowElement).style.backgroundColor = isHighlighted ? '#e0ffe0' : ''}
                                >
                                    <Table.TextCell flexBasis={70} flexShrink={0} flexGrow={0}>{startIndex + index + 1}</Table.TextCell>
                                    <Table.TextCell>{item.title}</Table.TextCell>
                                    <Table.TextCell>
                                        {`${format(eventDate, 'MM/dd/yy, h:mm a')} - ${format(new Date(item.end_date), 'h:mm a')}`}
                                    </Table.TextCell>
                                    <Table.TextCell>
                                        {item.is_robin === 1 ? 'Yes' : null}
                                    </Table.TextCell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            ) : (
                <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                    <Pane>No data available</Pane>
                </Pane>
            )}
        </div>
    );
};
