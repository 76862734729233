import { h, FunctionalComponent } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { Spinner, Pane, Button, Text, Pagination } from 'evergreen-ui';
import { ApiService } from '../ApiService';
import { CalendarTable } from './CalendarTable';
import { CalendarResponse } from "../CommonTypes";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, format } from 'date-fns';
import styles from './AdminCalendar.module.sass'

export const AdminCalendar: FunctionalComponent = () => {
    const [calendarData, setCalendarData] = useState<CalendarResponse>({ total: 0, items: [] });
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);
    const [itemsPerPage] = useState<number>(10);

    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(addDays(new Date(), 10));

    const fetchCalendarData = async (page: number, startDate?: Date | null, endDate?: Date | null) => {
        setLoading(true);
        setError(null);
        try {
            const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
            const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;
            const data = await ApiService.fetchCalendarData(page, itemsPerPage, formattedStartDate, formattedEndDate);
            setCalendarData(data);
        } catch (error) {
            console.error('Error fetching calendar data:', error);
            setError((error as any).message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCalendarData(page, startDate, endDate);
    }, [page, startDate, endDate]);

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handleDateChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setPage(1);
    };

    if (loading) {
        return (
            <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                <Spinner />
            </Pane>
        );
    }

    if (error) {
        return (
            <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                <Button appearance="primary" intent="danger">{error}</Button>
            </Pane>
        );
    }

    return (
        <div>
            <Pane marginBottom={16} className={styles.datePickerWrapper}>
                <Text>Select Date Range:</Text>
                <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate!}
                    endDate={endDate!}
                    selectsRange
                    isClearable
                    placeholderText="Select date range"
                    dateFormat="MM/dd/yy"
                    className={styles.datePicker}
                />
            </Pane>
            <CalendarTable
                calendarData={calendarData.items}
                startIndex={(page - 1) * itemsPerPage}
            />
            {calendarData.items.length > 0 ? (
            <Pane marginTop={16} display="flex" style={'align-items: center'}>
                <Pagination
                    page={page}
                    totalPages={Math.ceil(calendarData.total / itemsPerPage)}
                    onPageChange={handlePageChange}
                />
                <Text style={'margin-left: auto'}>Filtered results: {calendarData.total}</Text>
            </Pane>
            ): null}
        </div>
    );
};
