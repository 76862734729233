import './carousel/Carousel'
import './calendar/Calendar'
import './coaches/Coaches'
import './CounterComponent'
import './newsletter/Newsletter'
import './feedback/Feedback'
import './gallery/Gallery'
import './pricing/Pricing'
import './team/Team'
import './adminPanel/AdminPanel'
import './adminFeedback/AdminFeedback'

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the CSS styles

document.addEventListener("DOMContentLoaded", () => AOS.init({
    duration: 800
}));