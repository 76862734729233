import { h, FunctionComponent } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import styles from './SubscriptionForm.module.sass';
import { RECAPTCHA_KEY } from '../constants';
import { ApiService } from '../ApiService';

declare const grecaptcha: any;

interface FormState {
    fullName: string;
    email: string;
}

interface FormErrors {
    fullName?: string;
    email?: string;
    captcha?: string;
}

const SubscriptionForm: FunctionComponent = () => {
    const [formState, setFormState] = useState<FormState>({ fullName: '', email: '' });
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState<string | null>(null);

    const fullNameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        fullNameRef.current?.focus();

        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => document.body.removeChild(script);
    }, []);

    const handleChange = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
        const { name, value } = e.currentTarget;
        setFormState({ ...formState, [name]: value });
        setFormErrors({ ...formErrors, [name]: null }); // Clear error on change
    };

    const validateForm = (): boolean => {
        const errors: FormErrors = {};

        if (!formState.fullName) errors.fullName = 'Full name is required';
        if (!/\S+@\S+\.\S+/.test(formState.email)) errors.email = 'Email is invalid';
        if (!grecaptcha.getResponse()) errors.captcha = 'CAPTCHA is required';

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e: Event) => {
        e.preventDefault();
        setSubmitSuccess(false);
        setSubmitError(null);

        if (!validateForm()) return;

        setIsSubmitting(true);

        try {
            const reCAPTCHAToken = grecaptcha.getResponse();

            // Send request to backend using ApiService
            const response = await ApiService.subscribeToNewsletter({
                fullName: formState.fullName,
                email: formState.email,
                reCAPTCHAToken,
            });

            if (response.success) {
                setSubmitSuccess(true);
                setFormState({ fullName: '', email: '' });  // Clear form on success
            } else {
                setSubmitError(response.message);  // Display error message from backend
            }
        } catch (error) {
            setSubmitError((error as any).message);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.subscriptionForm}>
            <h2 className="section-header">Signup To Newsletter</h2>
            {submitSuccess ? (
                <div className={styles.success}>
                    Subscription successful!
                    <div className={styles.icon}>
                        <i class="fas fa-check-circle"></i>
                    </div>
                </div>
            ) : submitError ? (
                <div className={styles.error}>{submitError}</div>
            ) : (
                <div className="form-fields">
                    <div className="form-group">
                        <label htmlFor="fullName" className="form-label">Full Name</label>
                        <input
                            ref={fullNameRef}
                            type="text"
                            id="fullName"
                            name="fullName"
                            className="form-input"
                            value={formState.fullName}
                            onChange={handleChange}
                        />
                        {formErrors.fullName && <div className="error-message">{formErrors.fullName}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-input"
                            value={formState.email}
                            onChange={handleChange}
                        />
                        {formErrors.email && <div className="error-message">{formErrors.email}</div>}
                    </div>
                    <div className="form-group">
                        <div className="g-recaptcha" data-sitekey={RECAPTCHA_KEY}></div>
                        {formErrors.captcha && <div className="error-message">{formErrors.captcha}</div>}
                    </div>
                    <button type="submit" className={styles.button + ' button-black'} disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Subscribe'}
                    </button>
                </div>
            )}
        </form>
    );
};

export default SubscriptionForm;
