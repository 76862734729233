import { h, FunctionalComponent } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import register from 'preact-custom-element';
import styles from './AdminPanel.module.sass';
import { AdminFeedback } from "../adminFeedback/AdminFeedback";
import {USER_KEY} from "../constants";
import {Button, defaultTheme, mergeTheme, ThemeProvider} from 'evergreen-ui';
import {ApiService} from "../ApiService";
import {AdminCalendar} from "../adminCalendar/AdminCalendar";

const fontFamily = 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

const customTheme = mergeTheme(defaultTheme, {
    ...defaultTheme,
    fontSizes: ['12px', '15px', '16px', '20px', '24px', '32px'],
    fontFamilies: {
        display: fontFamily, // For headings and display text
        ui: fontFamily,           // For UI components like buttons
        mono: fontFamily,        // For code or monospaced text
    },
    components: {
        Button: {
            appearances: {
                primary: {
                    color: 'white',
                    borderRadius: 5,
                    backgroundColor: 'black',
                    selectors: {
                        _hover: {
                            backgroundColor: '#333333',
                        },
                        _active: {
                            backgroundColor: '#333333',
                        },
                        _focus: {
                            boxShadow: '0 0 0 2px gray',
                        },
                    }
                }
            },
        },
    },
})

const menuItems = [
    { id: 'collections', label: 'Collections', content: null },
    { id: 'feedback', label: 'Feedback', content: AdminFeedback },
    { id: 'calendar', label: 'Calendar', content: AdminCalendar },
];

// The component "watches" the localStorage and conditionally shows itself
const AdminPanel: FunctionalComponent = () => {
    const [user, setUser] = useState(() => {
        const storedUser = localStorage.getItem(USER_KEY);
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const [activeItem, setActiveItem] = useState(menuItems[0].id);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const updateUserState = () => {
            const storedUser = localStorage.getItem(USER_KEY);
            setUser(storedUser ? JSON.parse(storedUser) : null);
        };

        const overrideSetItem = () => {
            const originalSetItem = localStorage.setItem;
            localStorage.setItem = function (key: string, value: string) {
                originalSetItem.call(this, key, value);
                if (key === USER_KEY) {
                    updateUserState();
                }
            };
            return originalSetItem;
        };

        const overrideRemoveItem = () => {
            const originalRemoveItem = localStorage.removeItem;
            localStorage.removeItem = function (key: string) {
                originalRemoveItem.call(this, key);
                if (key === USER_KEY) {
                    updateUserState();
                }
            };
            return originalRemoveItem;
        };

        const originalSetItem = overrideSetItem();
        const originalRemoveItem = overrideRemoveItem();

        return () => {
            localStorage.setItem = originalSetItem;
            localStorage.removeItem = originalRemoveItem;
        };
    }, []);

    if (!user) {
        return null;
    }

    const handleItemClick = async (itemId: string) => {
        setActiveItem(itemId);
        setLoading(true); // Set loading to true before making the request
        setError(null);   // Clear any previous error

        const ncRoot = document.getElementById('nc-root');

        if (itemId === 'collections') {
            if (ncRoot) ncRoot.style.display = 'block';
            setLoading(false); // No need to check auth, stop loading
        } else {
            if (ncRoot) ncRoot.style.display = 'none';

            try {
                await ApiService.checkAuth();
                setLoading(false); // Stop loading after auth check
            } catch (error) {
                console.error('Authentication check failed:', error);
                setError('Unauthorized'); // Set error message
                setLoading(false); // Stop loading even if auth check fails
            }
        }
    };

    const handleReloadClick = () => {
        window.location.reload();
    };

    return (
        <ThemeProvider value={customTheme}>
            <div className={styles.adminPanel}>
                <div className={`${styles.inner} ${styles.contentWrapper}`}>
                    <div className={styles.logo}></div>
                    {menuItems.map(item => (
                        <div
                            key={item.id}
                            className={`${styles.item} ${activeItem === item.id ? styles.active : ''}`}
                            onClick={() => handleItemClick(item.id)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.activeTab}>
                {loading && <div className={styles.loading}>Loading...</div>}
                {!loading && error && (
                    <div className={styles.error}>
                        <p>Oops, you can't view this.</p>
                        <Button appearance="primary" onClick={handleReloadClick}>
                            Reload to Sign In
                        </Button>
                    </div>
                )}
                {!loading && !error && menuItems.map(item =>
                    activeItem === item.id && item.content ? (
                        <div key={item.id}>
                        <div className={styles.header}>
                                <div className={styles.contentWrapper}>{item.label}</div>
                            </div>
                            <div className={styles.contentWrapper}>
                                <div className={styles.content}>
                                    <item.content />
                                </div>
                            </div>
                        </div>
                    ) : null
                )}
            </div>
        </ThemeProvider>
    );
};

register(AdminPanel, 'sof-admin-panel');
