import { h, FunctionalComponent } from 'preact';
import { Dialog, Pane, Text } from 'evergreen-ui';
import {FeedbackItem} from "../CommonTypes";
import styles from "./AdminFeedback.module.sass";

interface FeedbackDetailsModalProps {
    isShown: boolean;
    feedback: FeedbackItem;
    onClose: () => void;
}

export const FeedbackDetailsModal: FunctionalComponent<FeedbackDetailsModalProps> = ({ isShown, feedback, onClose }) => {
    return (
        <Dialog
            isShown={isShown}
            title={feedback.fullName}
            onCloseComplete={onClose}
            confirmLabel="Close"
            hasCancel={false}
        >
            <Pane>
                <Text><b>Email:</b> <a
                    href={`mailto:${feedback.email}`}
                    className={styles.emailLink}
                    onClick={(e) => e.stopPropagation()}
                >
                    {feedback.email}
                </a></Text><br/>
                <Text><b>URL:</b> {feedback?.url}</Text><br/><br/>
                <Text>{feedback?.message}</Text>
            </Pane>
        </Dialog>
    );
};
