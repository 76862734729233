import { h, FunctionalComponent } from 'preact';
import { Table, Pane } from 'evergreen-ui';
import { FeedbackItem } from "../CommonTypes";
import styles from './AdminFeedback.module.sass';

interface FeedbackTableProps {
    feedbackData: FeedbackItem[];
    onRowClick: (item: any) => void;
    startIndex: number;
}

export const FeedbackTable: FunctionalComponent<FeedbackTableProps> = ({ feedbackData, onRowClick, startIndex }) => {
    return (
        <div>
            {feedbackData.length > 0 ? (
                <Table>
                    <Table.Head>
                        <Table.TextHeaderCell flexBasis={70} flexShrink={0} flexGrow={0}>#</Table.TextHeaderCell> {/* Shorter width */}
                        <Table.TextHeaderCell>Date</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Email</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Message</Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body>
                        {feedbackData.map((item, index) => (
                            <Table.Row
                                key={item.id}
                                isSelectable
                                onClick={() => onRowClick(item)}
                                onMouseEnter={(e: MouseEvent) => (e.currentTarget as HTMLTableRowElement).style.backgroundColor = '#f0f0f0'}
                                onMouseLeave={(e: MouseEvent) => (e.currentTarget as HTMLTableRowElement).style.backgroundColor = ''}
                            >
                                <Table.TextCell flexBasis={70} flexShrink={0} flexGrow={0}>{startIndex + index + 1}</Table.TextCell> {/* Shorter width */}
                                <Table.TextCell>{new Date(item.date).toLocaleDateString()}</Table.TextCell>
                                <Table.TextCell>{item.fullName}</Table.TextCell>
                                <Table.TextCell>
                                    <a
                                        href={`mailto:${item.email}`}
                                        className={styles.emailLink}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {item.email}
                                    </a>
                                </Table.TextCell>
                                <Table.TextCell>
                                    {item.message.length > 50 ? `${item.message.substring(0, 50)}...` : item.message}
                                </Table.TextCell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            ) : (
                <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                    <Pane>No feedback available</Pane>
                </Pane>
            )}
        </div>
    );
};
