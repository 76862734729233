import { h, FunctionalComponent } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { Spinner, Pane, Button, Text, Pagination } from 'evergreen-ui';
import { ApiService } from '../ApiService';
import { FeedbackTable } from './FeedbackTable';
import { FeedbackDetailsModal } from './FeedbackDetailsModal';
import { FeedbackItem, FeedbackResponse } from "../CommonTypes";

export const AdminFeedback: FunctionalComponent = () => {
    const [feedbackData, setFeedbackData] = useState<FeedbackResponse>({ total: 0, items: [] });
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedFeedback, setSelectedFeedback] = useState<FeedbackItem | undefined>();

    const [page, setPage] = useState<number>(1);
    const [itemsPerPage] = useState<number>(10);

    const fetchFeedbackData = async (page: number) => {
        setLoading(true);
        setError(null);
        try {
            const data = await ApiService.fetchFeedbackData(page, itemsPerPage);
            setFeedbackData(data);
        } catch (error) {
            console.error('Error fetching feedback data:', error);
            setError((error as any).message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFeedbackData(page);
    }, [page]);

    const handleRowClick = (item: FeedbackItem) => {
        setSelectedFeedback(item);
        setIsModalOpen(true);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const startIndex = (page - 1) * itemsPerPage;

    if (loading) {
        return (
            <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                <Spinner />
            </Pane>
        );
    }

    if (error) {
        return (
            <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                <Button appearance="primary" intent="danger">{error}</Button>
            </Pane>
        );
    }

    return (
        <div>
            <FeedbackTable
                feedbackData={feedbackData.items}
                onRowClick={handleRowClick}
                startIndex={startIndex}
            />
            {selectedFeedback && (
                <FeedbackDetailsModal
                    isShown={isModalOpen}
                    feedback={selectedFeedback}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
            <Pane marginTop={16} display="flex" style={'align-items: center'}>
                <Pagination
                    page={page}
                    totalPages={Math.ceil(feedbackData.total / itemsPerPage)}
                    onPageChange={handlePageChange}
                />
                <Text style={'margin-left: auto'}>Total: {feedbackData.total}</Text>
            </Pane>
        </div>
    );
};
