import { h } from 'preact';
import { useEffect, useState, useCallback } from 'preact/hooks';
import register from 'preact-custom-element';
import { CalendarDay, CalendarEvent } from './Types';
import { CalendarDesktop } from './CalendarDesktop';
import { CalendarMobile } from './CalendarMobile';
import styles from './Calendar.module.sass';
import {ApiService} from "../ApiService";

const useFetchEvents = () => {
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [eventsLoaded, setEventsLoaded] = useState<boolean>(false);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const data = await ApiService.getCalendarEvents();
                setEvents(data);
                setEventsLoaded(true);
            } catch (error) {
                console.error('Error fetching calendar events:', error);
                setEventsLoaded(false);
            }
        };
        fetchEvents();
    }, []);

    return { events, eventsLoaded };
};

const detectMobile = () => window.innerWidth < 835;

const Calendar = () => {
    const [currentWeek, setCurrentWeek] = useState<CalendarDay[]>([]);
    const [currentDayIndex] = useState<number>(new Date().getDay());
    const { events, eventsLoaded } = useFetchEvents();
    const [isMobile, setIsMobile] = useState<boolean>(detectMobile());

    useEffect(() => {
        generateCurrentWeek();

        const handleResize = () => setIsMobile(detectMobile());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const generateCurrentWeek = useCallback(() => {
        const today = new Date();
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
        const weekDates: CalendarDay[] = Array.from({ length: 7 }).map((_, i) => {
            const dayDate = new Date(startOfWeek);
            dayDate.setDate(startOfWeek.getDate() + i);
            return { day: getDayName(dayDate), date: dayDate };
        });
        setCurrentWeek(weekDates);
    }, []);

    const getDayName = useCallback((date: Date): string => date.toLocaleDateString('en-US', { weekday: 'long' }), []);

    return (
        <div className={styles.calendar}>
            {isMobile ? (
                <div class={styles.ipad}>
                    <CalendarMobile
                        currentWeek={currentWeek}
                        eventsLoaded={eventsLoaded}
                        events={events}
                        currentDayIndex={currentDayIndex}
                    />
                </div>
            ) : (
                <div class={styles.desktop}>
                    <CalendarDesktop
                        currentWeek={currentWeek}
                        eventsLoaded={eventsLoaded}
                        events={events}
                        currentDayIndex={currentDayIndex}
                    />
                </div>
            )}
        </div>
    );
};

register(Calendar, 'sof-calendar');
