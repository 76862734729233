import {CalendarResponse, FeedbackResponse, GitLabUser} from './CommonTypes';
import { USER_KEY } from './constants';
import {CalendarEvent} from "./calendar/Types";
import {parse} from "date-fns";

const API_BASE_URL = window.location.hostname === 'localhost' ? 'http://localhost:3000/api' : '/api';

type CustomHeaders = HeadersInit & {
    'X-Auth'?: string;
    'X-Gitlab-Token'?: string;
};

export const ApiService = {

    getHeaders: (): CustomHeaders => {
        const storedUser = localStorage.getItem(USER_KEY);
        const authToken = localStorage.getItem('token');

        const headers: CustomHeaders = {
            'Content-Type': 'application/json',
        };

        if (storedUser) {
            const gitLabUser = JSON.parse(storedUser) as GitLabUser;
            headers['X-Gitlab-Token'] = gitLabUser.token;
        }

        if (authToken) {
            headers['X-Auth'] = `Bearer ${authToken}`;
        }

        return headers;
    },

    handleTokenInResponse: (response: Response) => {
        const newAuthToken = response.headers.get('X-Auth');
        if (newAuthToken) {
            const token = newAuthToken.replace('Bearer ', '');
            localStorage.setItem('token', token);
        }
    },

    customRequest: async (url: string, options: RequestInit, auth = true): Promise<any> => {
        const headers = ApiService.getHeaders();

        /*if (!headers['X-Auth'] && !headers['X-Gitlab-Token']) {
            throw new Error('No authentication tokens available');
        }*/

        const response = await fetch(API_BASE_URL + url, {
            ...options,
            headers: {
                ...options.headers,
                ...headers,
            },
        });
        ApiService.handleTokenInResponse(response);
        if (!response.ok) {
            const errorData = await response.json();
            throw { message: errorData.message || 'An error occurred' };
        }
        return response.json();
    },

    async fetchFeedbackData(page: number = 1, limit: number = 30): Promise<FeedbackResponse> {
        const url = `/feedback/all?page=${page}&limit=${limit}`;
        return ApiService.customRequest(url, {
            method: 'GET',
        }, false);
    },

    async fetchCalendarData(page: number = 1, limit: number = 30, start: string | null, end: string | null): Promise<CalendarResponse> {
        let url = `/calendar/all?page=${page}&limit=${limit}`;
        if (start) url += `&startDate=${start}`;
        if (end) url += `&endDate=${end}`;
        return ApiService.customRequest(url, { method: 'GET' }, false);
    },

    async checkAuth(): Promise<{ success: boolean }> {
        return ApiService.customRequest(`/auth`, {
            method: 'GET',
        });
    },

    sendFeedback: async (formData: { name: string; email: string; message: string; url: string; captcha: string }) => {
        return ApiService.customRequest(`/feedback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
    },

    subscribeToNewsletter: async (formData: { fullName: string; email: string; reCAPTCHAToken: string }) => {
        return ApiService.customRequest(`/newsletter/subscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
    },


    async getCalendarEvents(): Promise<CalendarEvent[]> {
        const url = '/calendar/events';
        const data = await ApiService.customRequest(url, {
            method: 'GET',
        });
        return data.map((event: CalendarEvent) => ({
            ...event,
            start_date: parse(event.start_date, 'yyyy-MM-dd HH:mm:ss', new Date()),
            end_date: parse(event.end_date, 'yyyy-MM-dd HH:mm:ss', new Date()),
        }));
    },
};
